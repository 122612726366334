import React, { FunctionComponent, useContext, useReducer } from 'react';
import { Button, Icon, Loader } from 'rs-emd-ui-atoms';
import { OrderList, OrderProductList } from '../..';
import { QuickOrderContext } from '../../../../../../components/app/quick-order-context';
import { OrderHistoryOrder, OrderSort } from '../../../../../../generated/graphql';
import { myAccountOrdersService } from '../../../../../../services/my-account-orders-service';
import { getLabel } from '../../../../helpers/html.utils';
import { Pagination } from '../../../product-list-page';
import { IPagination } from '../../../product-list-page/page-controls/pagination';
import { MetaDataAndTagging } from '../../../shared';
import sharedStyles from '../my-account-shared.module.scss';
import { Action, MyAccountOrdersProps, OrderActionType, State } from './my-account-orders-component.model';
import styles from './my-account-orders-component.module.scss';
import { OrdersHeaderArea } from './orders-header-area/orders-header-area-component';

export const MyAccountOrders: FunctionComponent<MyAccountOrdersProps> = (props: MyAccountOrdersProps) => {
  let defaultPageSize: number = +(process.env.REACT_APP_PLP_DEFUALT_PAGE_SIZE ?? 20);

  const [state, dispatch] = useReducer(reducer, {
    orders: props.data?.orderHistoryArea?.orders,
    pagination: {
      offset: 0,
      size: defaultPageSize,
      numberOfPages: Math.ceil((props.data?.orderHistoryArea?.noOfOrders ?? 0) / defaultPageSize),
      totalProducts: props.data?.orderHistoryArea?.noOfOrders ?? 0,
    },
    showList: (props.viewBy ?? 'products') === 'list',
  });
  const { setQuickOrderProps } = useContext(QuickOrderContext);

  function getPageInfo(isTop: boolean) {
    return (
      <p
        className={`${styles['page-info']} ${isTop ? styles.top : styles.bottom}`}
        dangerouslySetInnerHTML={{
          __html: getLabel(props.data.labels, 'results_counter')
            .replace('[page]', `<b>${(state.orders?.length ?? 0) + state.pagination.offset}</b>`)
            .replace('[total]', `<b>${props.data.orderHistoryArea?.noOfOrders}</b>`),
        }}></p>
    );
  }

  function getNoOrders() {
    return (
      <>
        <div className={sharedStyles.empty}>
          <Icon name='basket' width={60} height={60}></Icon>
          <div className={sharedStyles.details}>
            <p>{getLabel(props.data.labels, 'no_orders_header')}</p>
            <p>{getLabel(props.data.labels, 'no_orders_details')}</p>
          </div>
        </div>
        <div className={sharedStyles.buttons}>
          <Button buttonType='tertiary' href='/' isExternalLink={false} text={getLabel(props.data.labels, 'shop_now')}></Button>
          <Button
            buttonType='tertiary'
            text={getLabel(props.data.labels, 'quick_order')}
            onClick={() => {
              setQuickOrderProps({
                topicIdentifier: '', //props.topicIdentifier,
                openOnAddByStockNum: true,
                isVisible: true,
              });
            }}></Button>
        </div>
      </>
    );
  }

  return (
    <div className={`inner-page-wrapper ${styles['my-account-orders-container']}`}>
      {/* Head data */}
      <MetaDataAndTagging tagging={props.data.tagging} />

      {props.data.orderHistoryArea?.noOfOrders ? (
        <>
          <OrdersHeaderArea
            labels={props.data.labels}
            defaultValue={state.showList ? 'list' : 'products'}
            changeOrderTypeCallback={(isList) => dispatch({ type: OrderActionType.SetOrderDisplayType, isList: isList })}
          />
          {state.loadingOrders ? (
            <div className={styles['loader-container']}>
              <Loader />
            </div>
          ) : (
            <>
              {getPageInfo(true)}

              {state.showList ? (
                <OrderList
                  labels={props.data.labels}
                  orders={state.orders}
                  showSortArrows
                  sortMode={state.sort}
                  onSortCallback={(sortMode) => getOrders(undefined, sortMode)}
                />
              ) : (
                <>
                  {state.orders?.map((order) => (
                    <OrderProductList labels={props.data.labels} data={order} />
                  ))}
                </>
              )}
              {getPageInfo(false)}
              <Pagination
                className={styles.pagination}
                labels={props.data.labels}
                pagination={state.pagination}
                alwaysShowPageSizeControls={true}
                onPageSizeChange={(pagination) => getOrders(pagination)}
              />
            </>
          )}
        </>
      ) : (
        <>{getNoOrders()}</>
      )}
    </div>
  );

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case OrderActionType.SetOrderDisplayType:
        return {
          ...state,
          showList: action.isList,
        };
      case OrderActionType.SetOrders:
        // scroll to top if not changing page size
        if (action.pagination?.size === state.pagination.size) document.body.scrollTo(0, 0);

        let pagination = action.pagination ?? state.pagination;
        pagination.numberOfPages = Math.ceil(pagination.totalProducts / pagination.size);

        return {
          ...state,
          orders: action.orders,
          sort: action.sortMode,
          pagination: action.pagination ?? state.pagination,
        };
      case OrderActionType.SetLoadingOrders:
        return {
          ...state,
          loadingOrders: action.loading,
        };
    }
  }

  function getOrders(pagination?: IPagination, sort?: OrderSort) {
    dispatch({ type: OrderActionType.SetLoadingOrders, loading: true });

    let currPagination = pagination ?? state.pagination;
    let currSort = sort ?? state.sort;
    if ((props.data.orderHistoryArea?.noOfOrders ?? 0) <= currPagination.size || currSort !== state.sort) currPagination.offset = 0;

    myAccountOrdersService.getOrders(currPagination, currSort).then((res) => {
      dispatch({ type: OrderActionType.SetLoadingOrders, loading: false });
      if (res?.isSuccess) {
        dispatch({
          type: OrderActionType.SetOrders,
          orders: res.orderHistoryArea?.orders as OrderHistoryOrder[],
          sortMode: currSort,
          pagination: currPagination,
        });
      }
    });
  }
};
