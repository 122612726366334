import React, { FunctionComponent, useState } from 'react';
import { Accordion, ButtonToggle } from 'rs-emd-ui-atoms';
import { getLabel } from '../../../../../helpers/html.utils';
import { setAccountOrdersViewByCookie } from '../../../../../helpers/user-settings.utils';
import { ILabel } from '../../../../../models/common/label.model';
import { SearchField } from '../../../../shared/search-field/search-field-component';
import styles from './orders-header-area-component.module.scss';

export type OrdersHeaderAreaProps = {
  labels?: ILabel[];
  defaultValue?: string;
  changeOrderTypeCallback?: (showList: boolean) => void;
};

export const OrdersHeaderArea: FunctionComponent<OrdersHeaderAreaProps> = (props: OrdersHeaderAreaProps) => {
  const [viewByValue, setViewByValue] = useState<string | undefined>(props.defaultValue ?? 'products');

  const searchBar = <SearchField placeholder={getLabel(props.labels, 'search_placeholder')} />;

  const viewByToggle = (
    <ButtonToggle
      items={[
        {
          id: 'products',
          text: getLabel(props.labels, 'products'),
        },
        {
          id: 'list',
          text: getLabel(props.labels, 'list'),
        },
      ]}
      valueChangedCallback={(value) => {
        setViewByValue(value);
        setAccountOrdersViewByCookie(value);
        props.changeOrderTypeCallback && props.changeOrderTypeCallback(value === 'list');
      }}
      className={styles['view-by']}
      value={viewByValue}
    />
  );

  const gteTabletHeader = (
    <div className={`${styles.container} ${styles['gte-tablet']}`}>
      <div className={`${styles['header-item-container']} ${styles['search-container']}`}>
        <span className={styles.title}>{getLabel(props.labels, 'search')}</span>
        {searchBar}
      </div>
      <div className={styles['view-by-container']}>
        <div className={styles['header-item-container']}>
          <span className={styles.title}>{getLabel(props.labels, 'view_by')}</span>
          {viewByToggle}
        </div>
      </div>
    </div>
  );

  const mobileHeader = (
    <div className={`${styles.container} ${styles['mobile']}`}>
      <Accordion title={getLabel(props.labels, 'search')} className={styles['search-bar-accordion']}>
        <div className={styles['search-container']}>{searchBar}</div>
      </Accordion>
      <Accordion title={getLabel(props.labels, 'view_by')} className={styles['view-by-accordion']}>
        {viewByToggle}
      </Accordion>
    </div>
  );

  return (
    <>
      {mobileHeader}
      {gteTabletHeader}
    </>
  );
};

export default OrdersHeaderArea;
