import {
  OrderHistoryPage,
  OrderHistoryPageDocument,
  OrderHistoryPageQuery,
  OrderHistoryPageQueryVariables,
  OrderSort,
} from '../generated/graphql';
import { IPagination } from '../rs-emd-ui-modules/src/components/product-list-page/page-controls/pagination';
import { graphQLService } from './graphql-service';

export class MyAccountOrdersService {
  async getOrders(pagination: IPagination, sort?: OrderSort): Promise<OrderHistoryPage | undefined> {
    const result = await graphQLService.apolloClient.query<OrderHistoryPageQuery, OrderHistoryPageQueryVariables>({
      query: OrderHistoryPageDocument,
      variables: {
        sort: sort,
        page: { first: pagination.size, offset: pagination.offset },
      },
      fetchPolicy: 'network-only',
    });

    let res: OrderHistoryPage | undefined = undefined;

    if (result.data) {
      res = result.data.orderHistoryPage as OrderHistoryPage;
    }

    return res;
  }
}

export const myAccountOrdersService = new MyAccountOrdersService();
