import { OrderHistoryOrder, OrderHistoryPage, OrderSort } from '../../../../../../generated/graphql';
import { IPagination } from '../../../product-list-page/page-controls/pagination';

export type MyAccountOrdersProps = {
  data: OrderHistoryPage;
  viewBy?: string;
};

export type State = {
  showList?: boolean;
  loadingOrders?: boolean;
  orders?: OrderHistoryOrder[];
  pagination: IPagination;
  sort?: OrderSort;
};

export enum OrderActionType {
  SetOrderDisplayType = 'SetOrderDisplayType',
  SetOrders = 'SetOrders',
  SetLoadingOrders = 'SetLoadingOrders',
}

export type Action =
  | { type: OrderActionType.SetOrderDisplayType; isList: boolean }
  | { type: OrderActionType.SetOrders; orders?: OrderHistoryOrder[]; sortMode?: OrderSort; pagination?: IPagination }
  | { type: OrderActionType.SetLoadingOrders; loading: boolean };
